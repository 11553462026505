import { useProductsStore } from '~/store/productsStore.js'
import { useFilterStore } from '~/store/filterStore.js'
import { SHOP_ROUTES_NAMES } from '~/constants'

const { routeName } = useShopRouteName()

const productStore = useProductsStore()

//child of shopDiamond middleware in legacy code
// use for item, item-finish, item-diamond-details, diamond
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return
  console.log('shopItem middleware', to.query)
  try {
    if (
      routeName.value === SHOP_ROUTES_NAMES.ITEM ||
      routeName.value === SHOP_ROUTES_NAMES.ITEM_DIAMOND_DETAILS ||
      routeName.value === SHOP_ROUTES_NAMES.ITEM_DIAMOND
    ) {
      to.query.diamond = to.query.diamond || from.query.diamond

      if (to.query.item > 0 && (!productStore.item.Item || to.query.item !== from.query.item)) {
        const { categories, values, getQuery } = useFilterStore()
        productStore.getItem({
          category: to.query.category,
          subcategory: to.query.subcategory,
          item: to.query.item,
          query: { ...to.query, ...getQuery(true) },
          forceBandSettings: true,
        })
      }
    }
    if (routeName.value === SHOP_ROUTES_NAMES.ITEM_FINISH) {
      productStore.getItem({
        category: to.query.category,
        subcategory: to.query.subcategory,
        country: to.query.country,
        item: to.query.item,
        query: { ...to.query },
        forceBandSettings: true,
      })
    }
  } catch (e) {
    // const router = useRouter()
    // console.error('shopItem middleware', e)
    if (
      e.message == getSettingsExpress().Status.DIAMOND_NOT_SUPPORTED ||
      e.message == getSettingsExpress().Status.ERROR
    ) {
      // TODO: refactor and fix, related to abstract legacy router
      // await navigateTo({ name: 'diamond', query: to.query })
    } else {
      return false
    }
  }
  console.log('shopItem middleware end')
})
